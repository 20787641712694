import React from 'react';
import dayjs from 'dayjs';
import { Dygraph } from '@qogni/react-dygraphs';

import {
  apiTimeFormat, dailyQuantilesColors,
  legendFormatterDailyQuantiles, dailyQuantilesLabels, chartColor,
  dateAxisLabelFormatter, numberValueFormatter, dateValueFormatter,
} from '../helpers/commonHelpers';
import useDailyQuantiles from '../hooks/useDailyQuantiles';
import QmJAndDailyQuantilesLegend from './QmJAndDailyQuantilesLegend';

const dygraphStyle = { width: '100%' };
const dummyFn = () => {};
const QmjAndDailyQuantilesCharts = ({
  start,
  end,
  height,
  values = [],
  code,
}) => {
  const allDailyQuantiles = useDailyQuantiles();
  const { dailyQuantiles = [] } = allDailyQuantiles.find(data => data?.code === code) || {};
  const startedAt = dayjs(start, apiTimeFormat);
  const endedAt = dayjs(end, apiTimeFormat);
  const numExpectedQmJ = endedAt.diff(startedAt, 'day') + 1;
  // Prepare data for dygraph
  const formatedData = React.useMemo(() => Array.from({ length: numExpectedQmJ }, (_, index) => {
    const currentDate = startedAt.add(index, 'day');
    const { v = NaN } = values.find(qmj => qmj.d === currentDate.format(apiTimeFormat)) || {};
    const {
      maximum = NaN,
      minimum = NaN,
      q10 = NaN,
      q20 = NaN,
      q50 = NaN,
      q80 = NaN,
      q90 = NaN,
    } = dailyQuantiles.find(data => data?.day === currentDate.format('MM-DD')) || {};

    return [
      currentDate.toDate(),
      minimum,
      q10,
      q20,
      q50,
      q80,
      q90,
      maximum,
      v,
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [allDailyQuantiles, start, end]);

  const dygraphExtraProps = React.useMemo(
    () => {
      const graphProps = {
        axes: {
          y: {
            // we want to start the chart at zero but not to display it
            // on the chart
            axisLabelFormatter: numberValueFormatter(2),
            valueFormatter: numberValueFormatter(4),
            axisLabelWidth: 60,
          },
          x: {
            valueFormatter: dateValueFormatter(true),
          },
        },
        dateWindow: [startedAt.valueOf(), endedAt.valueOf()],
      };
      graphProps.data = formatedData;
      graphProps.colors = [...dailyQuantilesColors, ...chartColor];
      graphProps.fillGraph = true;
      graphProps.connectSeparatedPoints = false;
      graphProps.fillAlpha = 1.0;
      graphProps.series = {
        QmJ: {
          fillGraph: false,
          strokeWidth: 2,
          stackedGraph: true,
        },
      };
      /*
       We have to reverse curves drawing in order to prevent from mixing color.
       So, as Dygraph get the first highlighted point to position the legend,
       the legend was drawn related to the minimum and under the canvas.
       Because the area of the legend popup is quite large in this case,
       we position it as a fixed value for the y position.
       (The x position still follow the higlited point).

      */
      graphProps.onHighlight = (e, x, pts) => {
        const [legendDiv = null] = document.getElementsByClassName('dygraph-legend');
        if (legendDiv) {
          legendDiv.style.top = '20px';
        }
      };
      graphProps.legendFormatter = legendFormatterDailyQuantiles;
      graphProps.labels = ['Date', ...dailyQuantilesLabels, 'QmJ'];
      graphProps.axisLabelFormatter = dateAxisLabelFormatter;
      graphProps.pixelRatio = 2;

      return graphProps;
    },
    [startedAt, endedAt, formatedData],
  );

  return (
    <>
      <Dygraph
        legend="follow"
        height={height}
        style={dygraphStyle}
        includeZero
        underlayCallback={dummyFn}
        {...dygraphExtraProps}
      />
      <QmJAndDailyQuantilesLegend />
    </>
  );
};

export default React.memo(QmjAndDailyQuantilesCharts);
