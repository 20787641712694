import React from 'react';
import dayjs from 'dayjs';

import { DataGrid } from '@material-ui/data-grid';

import DataGridPagination from './DataGridPagination';

const dataGridComponentOverrides = {
  pagination: DataGridPagination,
};

const getNumberColumn = (fieldName, headerName) => ({
  field: fieldName,
  headerName,
  type: 'number',
  width: 100,
  sortComparator: (v1, v2) => (v1 - v2),
});

const getFormattedColumns = columns => columns.map(value => getNumberColumn(value, value));

// columns and rows are expected as array
// first column is always a date
const StandardTable = ({ dateField = 'd', columns = [], rows = [], isLoading }) => {
  const tableColumns = React.useMemo(
    () => {
      const dateColumn = {
        field: dateField,
        headerName: 'Date',
        type: 'date',
        width: 200,
        valueFormatter: ({ value }) => `${dayjs(value).format('DD/MM/YYYY HH:mm')}`,
      };

      return [
        dateColumn,
        ...getFormattedColumns(columns),
      ];
    },
    [columns, dateField],
  );

  const tableRows = React.useMemo(
    () => rows.map(
      (row, rowIndex) => {
        const newRow = Object.fromEntries(
          row.map((rowCell, cellIndex) => ([tableColumns[cellIndex].field, rowCell])),
        );
        newRow.id = rowIndex;
        return newRow;
      },
    ),
    [rows, tableColumns],
  );

  return (
    <DataGrid
      rowHeight={30}
      spacing={0}
      columns={tableColumns}
      rows={tableRows}
      pageSize={25}
      sortModel={[
        {
          field: dateField,
          sort: 'desc',
        },
      ]}
      autoHeight
      hideFooterRowCount
      hideFooterSelectedRowCount
      disableSelectionOnClick
      loading={isLoading}
      components={dataGridComponentOverrides}
    />
  );
};

export default React.memo(StandardTable);
