import React from 'react';
import dayjs from 'dayjs';
import DayJsUtils from '@date-io/dayjs';

import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';

import { dataStartDate } from '../helpers/commonHelpers';

import 'dayjs/locale/fr';

dayjs.locale('fr');

const defaultInputProps = { style: { textAlign: 'center' } };

const CustomDatePicker = props => {
  const value = useSelector(({ mainDate }) => mainDate);
  const dispatch = useDispatch();

  const handleChange = newValue =>
    dispatch({ type: 'DATE_SET', date: dayjs(newValue).format('YYYY-MM-DD') });

  const today = new Date();

  return (
    <MuiPickersUtilsProvider locale="fr" utils={DayJsUtils}>
      <DatePicker
        format="DD/MM/YYYY"
        disableFuture
        minDate={dataStartDate}
        maxDate={today}
        value={value}
        onChange={handleChange}
        inputProps={defaultInputProps}
        showTodayButton
        cancelLabel="Annuler"
        okLabel="Valider"
        todayLabel="Aujourd'hui"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

export default React.memo(CustomDatePicker);
