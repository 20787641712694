import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import {
  getAnteriorityConfiguration, toQueryString,
  getEndOfDay23H59, getStartOfDay00H00,
} from '../helpers/commonHelpers';

/**
 * Returns the start and end dates deduced from a given date and an anteriority id.
 * @param {string} anteriorityId Anteriority identifier.
 * @param {string} endDate       ISO date string of the series' end date.
 *                               Note: Only the day will be taken into account.
 *                               Hours, minutes & seconds are replaced by
 *                               23h59m59s to make sure the whole day is considered.
 * @return {Object} Start and end dates as DayJS objects. Structure:
 *                  {
 *                    'start': DayJS,
 *                    'end': DayJS,
 *                  }
 */
const getStartEndDatesFromDateAndAnteriority = (anteriorityId, endDate) => {
  const anteriorityConf = getAnteriorityConfiguration(anteriorityId);
  const endDay00H00 = getStartOfDay00H00(dayjs(endDate));
  const endDay23H59 = getEndOfDay23H59(endDay00H00);
  const endFollowingDay00H00 = endDay00H00.add(1, 'day');
  const startAt = endFollowingDay00H00
    .subtract(anteriorityConf.period[0], anteriorityConf.period[1]);

  return {
    start: startAt,
    end: endDay23H59,
  };
};

/**
 * Gets the URL used for retrieving data for one station.
 * @param {string} code          Station code to retrieve data for.
 * @param {string} quantity      Quantity identifier.
 * @param {string} anteriorityId Anteriority identifier.
 * @param {string} endDate       ISO date string of the series' end date.
 *                               Note: Hours, minutes & seconds are replaced by
 *                               23h59m59s to make sure the whole day is considered.
 * @return {string} URL.
 */
const getStationUrl = (code, quantity, start, end) => {
  const pathname = `${process.env.GATSBY_LVDLR_API_URL}/api/observations`;
  const queryString = toQueryString({
    code,
    quantity,
    startAt: start.format('YYYY-MM-DDTHH:mm:ss[Z]'),
    endAt: end.format('YYYY-MM-DDTHH:mm:ss[Z]'),
  });

  return `${pathname}?${queryString}`;
};

/**
 * Gets the URL used for retrieving data for several stations.
 * @param {string[]} codes         List of station codes to retrieve data for.
 * @param {string}   quantity      Quantity identifier.
 * @param {string}   anteriorityId Anteriority identifier.
 * @param {string}   endDate       ISO date string of the series' end date.
 *                                 Note: Hours, minutes & seconds are replaced by
 *                                 23h59m59s to make sure the whole day is considered.
 * @return {string} URL.
 */
const getStationsUrl = (codes, quantity, start, end) => {
  const pathname = `${process.env.GATSBY_LVDLR_API_URL}/api/multiple-entities/observations`;
  const queryString = toQueryString({
    codes,
    quantity,
    startAt: start.format('YYYY-MM-DDTHH:mm:ss[Z]'),
    endAt: end.format('YYYY-MM-DDTHH:mm:ss[Z]'),
  });

  return `${pathname}?${queryString}`;
};

const useStationData = (isCompare, id, mode, anteriorityId, endDate) =>
  useQuery(
    ['stationChart', isCompare, id, mode, anteriorityId, endDate],
    async () => {
      const { start, end } = getStartEndDatesFromDateAndAnteriority(anteriorityId, endDate);
      const url = isCompare
        ? getStationsUrl(id, mode, start, end)
        : getStationUrl(id, mode, start, end);

      const response = await fetch(url);
      const result = await response.json();

      return {
        start: start.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        end: end.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        values: result,
      };
    },
    {
      retry: 1,
      staleTime: Infinity,
    },
  );

export default useStationData;
