import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles(theme => ({
  count: {
    padding: theme.spacing(0, 2),
  },
}));

const DataGridPagination = ({ paginationProps: { page, pageCount, setPage, rowCount } = {} }) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
      <Grid item>
        <Box className={classes.count}>
          {rowCount} résultats
        </Box>
      </Grid>
      {pageCount > 1 && (
        <Grid item>
          <Box className={classes.count}>
            <Pagination
              size="small"
              showFirstButton={pageCount > 4}
              showLastButton={pageCount > 4}
              count={pageCount}
              page={page}
              onChange={(event, newPage) => setPage(newPage)}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DataGridPagination;
