import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { chartThresholdsColor, chartColor, vcnxColor, vcnxAltColor } from '../helpers/commonHelpers';

const useStyle = makeStyles(theme => ({
  chartLegend: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    '& > span': {
      paddingLeft: theme.spacing(1),
    },
  },
  denseChartLegend: {
    fontSize: '0.61rem',
  },
}));

const legendLineStyles = {
  display: 'inline-block',
  marginRight: '5px',
  width: '16px',
  height: '2px',
  verticalAlign: 'middle',
};

const legendDotStyles = {
  display: 'inline-block',
  marginRight: '5px',
  width: '16px',
  height: '2px',
  verticalAlign: 'middle',
  backgroundPosition: 'top',
  backgroundSize: '4px 2px',
  backgroundRepeat: 'repeat-x',
};

const getThresholdsLegend = (thresholds, dense = false) => {
  if (thresholds) {
    return Object.entries(thresholds).map(
      ([label], index) => (
        <ChartLegendItem
          key={label}
          color={chartThresholdsColor[index]}
          label={label.toUpperCase()}
          dense={dense}
        />
      ),
    );
  }

  return null;
};

const ChartLegendItem = ({ color, label, type = 'line', dense = false }) => {
  const styles = type === 'dot' ? {
    ...legendDotStyles,
    backgroundImage: `linear-gradient(to right, ${color} 50%, rgba(255, 255, 255, 0) 50%)`,
    width: dense ? '10px' : '16px',
  } : {
    ...legendLineStyles,
    backgroundColor: color,
    width: dense ? '10px' : '16px',
  };
  return (
    <span style={{ fontWeight: 'bold', color }}>
      <span style={styles} />
      {label}
    </span>
  );
};

const ChartLegend = ({ mode, thresholds, vcn3, vcn10, dense = false }) => {
  const classes = useStyle();
  const label = mode === 'H' ? 'Hauteur' : mode;
  // Prepare global div classes.
  const legendClassName = clsx({
    [classes.chartLegend]: true,
    [classes.denseChartLegend]: dense,
  });

  return (
    <div className={legendClassName}>
      <ChartLegendItem label={label} color={chartColor} dense={dense} />
      {thresholds && mode !== 'H' && getThresholdsLegend(thresholds, dense)}
      {Array.isArray(vcn3) && vcn3.length !== 0 && (
        vcn3.map((datum, idx) => (<ChartLegendItem key={`vcn3-${datum.y}`} label={`VCN3 (${datum.y})`} color={idx % 2 ? vcnxColor : vcnxAltColor} type="dot" dense={dense} />))
      )}
      {Array.isArray(vcn10) && vcn10.length !== 0 && (
        vcn10.map((datum, idx) => (<ChartLegendItem  key={`vcn10-${datum.y}`} label={`VCN10 (${datum.y})`} color={idx % 2 ? vcnxColor : vcnxAltColor} type="dot" dense={dense} />))
      )}
    </div>
  );
};

export default ChartLegend;
