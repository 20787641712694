import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  dailyQuantilesColors,
  dailyQuantilesLabels, chartColor,
} from '../helpers/commonHelpers';

const useStyle = makeStyles(theme => ({
  chartLegend: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    '& > span': {
      paddingLeft: theme.spacing(1),
    },
  },
  denseChartLegend: {
    fontSize: '0.7rem',
  },
  legendItemWrapper: ({ color }) => ({
    fontWeight: 'bold',
    color,
  }),
  legendItem: ({ color, height }) => ({
    display: 'inline-block',
    marginRight: 5,
    backgroundColor: color,
    width: 16,
    height,
    verticalAlign: 'middle',
  }),
}));

const ChartLegendItem = ({ color, label, type = 'line' }) => {
  const height = type === 'fill' ? 8 : 3;
  const classes = useStyle({ color, height });

  return (
    <span className={classes.legendItemWrapper}>
      <span className={classes.legendItem} />
      {label}
    </span>
  );
};

const QmJAndDailyQuantilesLegend = () => {
  const classes = useStyle();
  const labels = React.useMemo(
    () => [...dailyQuantilesLabels].reverse(),
    [],
  );
  const colors = React.useMemo(
    () => [...dailyQuantilesColors].reverse(),
    [],
  );

  return (
    <div className={classes.chartLegend}>
      <ChartLegendItem label="QmJ" color={chartColor} />
      {labels.map((dailyQuantile, index) => (
        <ChartLegendItem
          key={dailyQuantile}
          label={dailyQuantile}
          color={colors[index]}
          type="fill"
        />
      ))}
    </div>
  );
};

export default QmJAndDailyQuantilesLegend;
