import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import useStationData from '../hooks/useStationData';
import StationChartTable from './StationChartTable';
import QuantityChart from './QuantityChart';
import QmjAndDailyQuantilesChart from './QmjAndDailyQuantilesChart';

const useStyles = makeStyles(theme => ({
  dygraph: {
    width: '100%',
    '& .dygraph-legend': {
      background: '#ffffff',
      position: 'absolute',
      padding: theme.spacing(1),
      '& > span': {
        display: 'block',
      },
    },
  },
  densePopupLegend: {
    fontSize: '0.8rem',
  },
  denseLegend: {
    fontSize: '0.8rem',
  },
  chartsWrapper: {
    padding: theme.spacing(1, 2, 2),
  },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  dailyQuantilesSwitch: ({ canDisplayDailyQuantiles }) => ({
    display: canDisplayDailyQuantiles ? 'flex' : 'block',
    alignItems: canDisplayDailyQuantiles ? 'center' : 'inherit',
    '& > label': { marginRight: '0.4rem' },
  }),
}));

const getUnit = mode => (mode === 'H' ? 'Hauteur en mm' : 'Débit en m³/s');

const StationChart = ({
  className = '',
  id,
  mode,
  anteriorityId,
  table = false,
  height,
  dense = false,
  isCompare = false,
  thresholds = null,
  hasDailyQuantiles = false,
  vcn3 = [],
  vcn10 = [],
}) => {
  const endDate = useSelector(({ mainDate }) => mainDate);
  const canDisplayDailyQuantiles = hasDailyQuantiles && !isCompare && !dense && (mode === 'QmJ');
  const classes = useStyles({ canDisplayDailyQuantiles });
  const {
    isLoading,
    data: {
      start = null,
      end = null,
      values = [],
    } = {},
  } = useStationData(isCompare, id, mode, anteriorityId, endDate);

  const [withDailyQuantiles, setWithDailyQuantiles] = React.useState(false);

  React.useEffect(() => {
    if (!canDisplayDailyQuantiles && withDailyQuantiles) {
      setWithDailyQuantiles(false);
    }
  }, [canDisplayDailyQuantiles, withDailyQuantiles]);

  const toggleDailyQuantiles = () => setWithDailyQuantiles(!withDailyQuantiles);

  const columns = isCompare
    ? id // array of station id
    : [mode]; // current data mode

  if (table) {
    return (
      <StationChartTable data={values} columns={columns} isLoading={isLoading} />
    );
  }

  const showData = !isLoading && values.length !== 0;
  const emptyData = !isLoading && values.length === 0;

  // Prepare global div classes.
  const boxClassName = `${className} ${clsx({
    [classes.dygraph]: true,
    [classes.densePopupLegend]: dense,
  })}`;

  const commonProps = {
    start,
    height,
    end,
    values,
  };

  const content = (
    <Box className={boxClassName}>
      {isLoading && (
        <Box className={classes.spinner}>
          <CircularProgress />
        </Box>
      )}
      {showData && (
        <>
          <Grid container justifyContent="space-between" alignItems="flex-start">
            <Typography
              variant="body1"
              className={dense ? classes.denseLegend : ''}
            >
              {getUnit(mode)}
            </Typography>
            <div className={classes.dailyQuantilesSwitch}>
              {canDisplayDailyQuantiles && (
              <FormControlLabel
                className={classes.dailyQuantilesSwitch}
                labelPlacement="start"
                control={<Switch checked={withDailyQuantiles} onChange={toggleDailyQuantiles} />}
                label="Afficher les données statistiques"
                data-html2canvas-ignore
              />
              )}
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={(
                  <>
                    {'Pour zoomer sur le graphique, effectuez un '} <b>cliquer-glisser</b> {' sur la zone d\'intéret.'} <br />
                    {'Pour revenir à la vue initiale, '} <b>double-cliquez</b> {' sur le graphique.'}
                  </>
          )}
              >
                <InfoIcon color="disabled" fontSize="small" />
              </Tooltip>
            </div>

          </Grid>
          {!withDailyQuantiles && (
            <QuantityChart
              {...commonProps}
              mode={mode}
              isCompare={isCompare}
              dense={dense}
              thresholds={thresholds}
              vcn3={vcn3}
              vcn10={vcn10}
              id={id}
            />
          )}
          {withDailyQuantiles && (
            <QmjAndDailyQuantilesChart
              {...commonProps}
              code={id}
            />
          )}
        </>
      )}
      {emptyData && (<Alert severity="warning">Aucune donnée</Alert>)}
    </Box>
  );

  return (
    dense
      ? <Box className={classes.chartsWrapper}> { content } </Box>
      : <Paper className={classes.chartsWrapper}> { content } </Paper>
  );
};

export default React.memo(StationChart);
