import React from 'react';
import clsx from 'clsx';

import { Grid, Tooltip, Typography } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { frontAnteriorityConfigs } from '../helpers/commonHelpers';

const useStyles = makeStyles({
  label: {
    display: 'block',
  },
  labelRight: {
    textAlign: 'right',
  },
  buttonGroup: {
    '& .Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.05)',
    },
  },
});

const useDenseStyles = makeStyles(theme => ({
  root: {
    '& .MuiToggleButton-sizeSmall': {
      fontSize: '0.7rem',
      paddingTop: theme.spacing(0.25),
      paddingBottom: theme.spacing(0.25),
    },
    '& label': {
      display: 'none',
    },
  },
}));

const StationDataToggleButtons = ({
  activeDataModeId,
  handleDataModeChange,
  activeAnteriorityId,
  handleAnteriorityChange,
  dense = false,
  availableDataModes,
  anteriorityConfigs = frontAnteriorityConfigs,
  ...otherProps
}) => {
  const classes = useStyles();
  const denseClasses = useDenseStyles();

  return (
    <Grid container justifyContent="space-between" className={clsx({ [denseClasses.root]: dense })} {...otherProps}>
      <Grid item>
        <Typography variant="body2" component="label" className={classes.label}>
          Données
        </Typography>

        <ToggleButtonGroup
          size="small"
          value={activeDataModeId}
          exclusive
          onChange={handleDataModeChange}
          className={classes.buttonGroup}
        >
          {availableDataModes.map(({ id, label, description, disable }) => {
            if (!disable) {
              // A value is passed to Tooltip because ToggleButtonGroup listens
              // to events coming from first level children only...
              // See https://github.com/mui-org/material-ui/issues/18091
              return (
                <Tooltip key={id} value={id} title={description} arrow>
                  <ToggleButton
                    value={id}
                  >
                    {label}
                  </ToggleButton>
                </Tooltip>
              );
            }

            return null;
          })}
        </ToggleButtonGroup>
      </Grid>

      <Grid item>
        <Typography variant="body2" component="label" className={clsx(classes.label, classes.labelRight)}>
          Antériorité
        </Typography>

        <ToggleButtonGroup
          size="small"
          value={activeAnteriorityId}
          exclusive
          onChange={handleAnteriorityChange}
        >
          {anteriorityConfigs.map(({ id, label, description }) => (
            <Tooltip key={label} value={id} title={description} arrow>
              <ToggleButton value={id}>{label}</ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default React.memo(StationDataToggleButtons);
