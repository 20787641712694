import React from 'react';
import dayjs from 'dayjs';
import { Dygraph } from '@qogni/react-dygraphs';
import {
  drawThresholdsAndVcnx, legendFormatterSingleSeries, legendFormatterMultiSeries,
  chartColor, colors, apiTimeFormat,
  formatSingleSeriesForGraph, formatMultiSeriesForGraph,
  dateAxisLabelFormatter, numberValueFormatter, dateValueFormatter,
} from '../helpers/commonHelpers';
import ChartLegend from './ChartLegend';

const dygraphStyle = { width: '100%' };
const dummyFn = () => {};

const underlayCallback = (thresholds, vcn3, vcn10) => drawThresholdsAndVcnx(
  thresholds,
  vcn3,
  vcn10,
);

const legendFormatterSingle = (thresholds, mode, vcn3, vcn10) => legendFormatterSingleSeries(
  thresholds,
  mode,
  vcn3,
  vcn10,
);

const extractVcn = (vcn, start, end) => {
  if (Array.isArray(vcn) && vcn.length !== 0) {
    const startY = parseInt(dayjs(start, apiTimeFormat).format('YYYY'), 10)
    const endY = parseInt(dayjs(end, apiTimeFormat).format('YYYY'), 10)
    const years = Array(endY - startY + 1).fill().map((_, idx) => startY + idx)

    return vcn.filter(datum => years.indexOf(datum.y) !== -1) 
  }
  
  return [];
}

const QuantityChart = ({
  mode,
  start,
  end,
  height,
  dense,
  thresholds,
  isCompare,
  values,
  id,
  vcn3 = [],
  vcn10 = [],
}) => {
  let withLegend = false;
  let vcn3Values = [];
  let vcn10Values = [];
  const isDailyData = mode !== 'Qi' && mode !== 'QmH' && mode !== 'H';
  if (!isCompare) {
    vcn3Values = mode === 'Qm3J' && !dense ? extractVcn(vcn3, start, end) : [];
    vcn10Values = mode === 'Qm10J' && !dense ? extractVcn(vcn10, start, end) : [];
    withLegend = true;
  }
  const dygraphExtraProps = React.useMemo(
    () => {
      const graphProps = {
        axes: {
          y: {
            // we want to start the chart at zero but not to display it
            // on the chart
            axisLabelFormatter: numberValueFormatter(2),
            valueFormatter: numberValueFormatter(4),
            axisLabelWidth: 60,
          },
          x: {
            valueFormatter: dateValueFormatter(isDailyData),
          },
        },
        dateWindow: [dayjs(start, apiTimeFormat).valueOf(), dayjs(end, apiTimeFormat).valueOf()],
      };
      if (dense) {
        graphProps.axisLabelFontSize = 12;
        graphProps.axisLabelWidth = 25;
        graphProps.axes.y.axisLabelWidth = 50;
      }
      if (isCompare) {
        graphProps.data = formatMultiSeriesForGraph(values, id, mode, start, end);
        graphProps.colors = colors;
        graphProps.labels = ['Date', ...id];
        graphProps.connectSeparatedPoints = true;
        graphProps.legendFormatter = legendFormatterMultiSeries;
      } else {
        graphProps.data = formatSingleSeriesForGraph(values, mode, start, end);
        graphProps.colors = chartColor;
        graphProps.fillGraph = true;
        graphProps.connectSeparatedPoints = false;
        const hasThresholdAndIsNotH = (thresholds || vcn3Values.length !== 0 || vcn10Values !== 0) && (mode !== 'H');
        Boolean(hasThresholdAndIsNotH) && (
          graphProps.underlayCallback = underlayCallback(thresholds, vcn3Values, vcn10Values)
        );
        graphProps.legendFormatter = legendFormatterSingle(
          hasThresholdAndIsNotH ? thresholds : null,
          mode,
          vcn3Values,
          vcn10Values,
        );
      }
      graphProps.axisLabelFormatter = dateAxisLabelFormatter;
      graphProps.pixelRatio = 2;

      return graphProps;
    },
    [start, end, dense, isCompare, vcn3Values, thresholds, vcn10Values, id, mode, values, isDailyData],
  );

  return (
    <>
      <Dygraph
        legend="follow"
        height={height}
        style={dygraphStyle}
        includeZero
        underlayCallback={dummyFn}
        {...dygraphExtraProps}
      />
      {withLegend && (
        <ChartLegend
          mode={mode}
          thresholds={thresholds}
          vcn3={vcn3Values}
          vcn10={vcn10Values}
          dense={dense}
        />
      )}
    </>
  );
};

export default React.memo(QuantityChart);
