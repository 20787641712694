/* eslint-disable no-plusplus */
import { graphql, useStaticQuery } from 'gatsby';

export const useDailyQuantiles = () => {
  const { wrapper: { nodes = [] } = {} } = useStaticQuery(graphql`{
    wrapper: allDailyQuantiles {
      nodes {
        id
        code
        dailyQuantiles {
          day
          maximum
          minimum
          numYears
          q20
          q10
          q50
          q90
          q80
        }
      }
    }
  }`);

  return nodes;
};

export default useDailyQuantiles;
