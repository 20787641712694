import React from 'react';

import { isObject } from '@material-ui/data-grid';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';
import StandardTable from './StandardTable';

const useStyles = makeStyles(theme => ({
  tableWrapper: {
    padding: theme.spacing(1, 2, 2),
  },
}));

const StationChartTable = ({ data = [], columns = [], isLoading }) => {
  const classes = useStyles();
  // output data as [date, val1, val2, ...]
  const formatTableRows = React.useMemo(
    () => data.map(
      ({ d: date, v: value }) => {
        // value can be an integer or an object
        // we wan to output it as an array of values
        const rowValues = isObject(value)
          ? Object.values(value)
          : [value];

        return [date, ...rowValues];
      },
    ),
    [data],
  );

  const emptyData = data.length === 0;

  return (
    <>
      {!emptyData && (
        <StandardTable
          columns={columns}
          rows={formatTableRows}
          isLoading={isLoading}
        />
      )}
      {emptyData && (<Paper className={classes.tableWrapper}><Alert severity="warning">Aucune donnée</Alert></Paper>)}
    </>
  );
};

export default React.memo(StationChartTable);
